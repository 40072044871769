import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Entity } from '@proman/services/entity.service';
import { Article, Product } from '@proman/interfaces/entity-interfaces';
import { FilterService } from '@proman/services/filter.service';
import { QueryExpressionService } from '@proman/services/query-expression.service';

@Component({
    selector: 'pm-product-search-dialog',
    template: `
        <pro-dialog-title title="search"></pro-dialog-title>
        <div mat-dialog-content fxLayout="column">
            <pm-camera-qr-reader (onCodeRead)="handleSearch($event)"></pm-camera-qr-reader>

            <pm-txt [value]="query"
                    [config]="{ label: 'search' }"
                    (onChange)="handleSearch($event)"
            ></pm-txt>

            <mat-list>
                <mat-list-item *ngFor="let item of products" class="Clickable" (click)="select(item)">
                    <h4 mat-line>{{ item.name }}</h4>
                </mat-list-item>
                <hr>
            </mat-list>

        </div>
        <pro-dialog-actions></pro-dialog-actions>
    `
})

export class ProductSearchDialogComponent {
    query: string = '';
    products: Product[] = [];
    articleId: number;

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: { article?: Article },
        private Entity: Entity,
        private Filter: FilterService,
        private QueryExpression: QueryExpressionService,
        private dialogRef: MatLegacyDialogRef<ProductSearchDialogComponent>,
    ) {
        this.articleId = data.article && data.article.id;
    }

    handleSearch(query: string) {
        if (query) {
            this.Entity.get('product')
                .search({
                    'search': {
                        'productBarcode.value': this.QueryExpression.eqStrict(query),
                        'name': this.QueryExpression.eqStrict(query)
                    },
                    'article.id': this.articleId || [],
                    'join': ['article', 'productBarcode']
                })
                .then((response: Product[]) => {
                    this.products = response.map((product) => {
                        if (product.productBarcode) product.name += ` | ${product.productBarcode.value}`;
                        return product;
                    });
                });

        } else {
            this.products = [];

        }

    }

    select(product: Product) {
        this.dialogRef.close(product);
    }

}
